<template>

   <div class="xs:form-group-12">
      <label for="workspace_url">{{labelMessage}}:</label>
      <div class="input-group" :class=errorClass>
         <input
            v-model="oldValue"
            class="form-control"
            type="text"
            name="workspace_url"
            id="workspace_url"
            autocomplete="off"
            required>

         <div class="input-group-append">
            {{ domain }}
         </div>
      </div>

      <label v-if="errorMessage !== ''" class="field-status">{{errorMessage}}</label>
      <p v-if="oldValue"><small><em>Your URL will be: {{oldValue.toLowerCase()}}{{domain}}</em></small></p>

   </div>
</template>

<script>
   export default {
      props: ['labelMessage', 'errorMessage', 'errorClass', 'domain', 'oldValue']
   }
</script>
