<template>
   <transition name="fade">
      <div v-if="show" class="message vertical-offset:base" :class="messageType">
         <div class="container--lock">
            <div class="row">
               <div class="xs:col-10">
                  <div class="message__text">
                     <slot>{{otherMessage}}</slot>
                  </div>
               </div>
               <div class="xs:col-2 text-right">
                  <a href="#" v-on:click="show = !show"><i class="fa fa-times"></i></a>
               </div>
            </div>
         </div>
      </div>
   </transition>
</template>

<style>
.fade-enter-active, .fade-leave-active {
   transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
   opacity: 0;
}
</style>


<script>
   export default {
      props: ['messageType', 'otherMessage'],

      data() {
         return { show: true };
      }

   }
</script>
